<template>
  <div class="wayfinder-input">
    <input
      ref="input"
      :placeholder="placeholder"
      :tabindex="tabindexValue"
      :value="value"
      class="wayfinder-input--input"
      data-focus-frame-follow="500"
      type="search"
      @blur="_restoreFocus"
      @change="$emit('change', $event.target.value)"
      @focus="_resetCursor"
      @input="$emit('input', $event.target.value)"
      :title="title"
      :aria-label="ariaLabelValue"
    >

    <wayfinder-button
      :class="[
        'wayfinder-input--clear-button',
        { 'wayfinder-input--clear-button_hidden': !value }
      ]"
      :tabindex-value="value ? 0 : -1"
      :title="$l10n('clear input')"
      corners="square"
      modificator="only-text"
      size="medium"
      @click.native="$emit('input', '')"
    >
      <i class="fa-light fa-times"></i>
    </wayfinder-button>
  </div>
</template>

<style lang="less" src="./input.less"></style>
<script>
  import isElementOf from "../../../dashboard/lib/is-element-of.js";
  import WayfinderButton from "../button/button.vue";


  export default {
    name: "wayfinder-input",
    props: {
      value: String,
      placeholder: String,
      /* Re-focus input when the element on blur has class `forceFocus` or a child of such element. */
      forceFocus: String,
      tabindexValue: Number,
      ariaLabelValue: {
        value: String,
        default: ""
      },
      title: {
        value: String,
        default: ""
      }
    },
    data() {
      return {
        resetCursor: false
      };
    },
    methods: {

      blur() {
        return this.$refs.input.blur();
      },
      focus() {
        return this.$refs.input.focus();
      },
      getCursor() {
        return this.$refs.input.selectionStart;
      },
      setCursor(position) {
        this.$refs.input.setSelectionRange(position, position);
      },
      _resetCursor() {
        this.$emit("focus", event);

        if (this.resetCursor) {
          this.setCursor(-1);
          this.resetCursor = false;
        }
      },
      _restoreFocus(event) {


        if (!this.forceFocus || !isElementOf(event.relatedTarget, this.forceFocus)) {
          this.resetCursor = true;
          this.$emit("blur");
          return;
        }


        const lastCursorPostion = this.getCursor();
        this.$refs.input.focus();
        this.setCursor(lastCursorPostion);
      }
    },
    components: {
      WayfinderButton
    }
  };
</script>
