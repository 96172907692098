<template>
  <button
    :title="title"
    :aria-label="ariaLabelValue"
    :class="[
      'wayfinder-button',
      `wayfinder-button_${ corners }`,
      color ? `color-${ color }` : '',
      `${ size }-button`,
      modificator,
      { active }
    ]"
    :tabindex="tabindexValue"
  >
    <slot></slot>
  </button>
</template>

<style lang="less" src="./button.less"/>
<script>
  export default {
    name: "wayfinder-button",
    props: {
      title: {
        type: String,
        default: ""
      },
      ariaLabelValue: String,
      tabindexValue: Number,
      /* The color of button background. */
      color: {
        type: String,
        default: "",
        validator: _color => [
          "",
          "primary",
          "secondary",
          "black",
          "gray",
          "light-gray",
          "white"
        ].includes(_color)
      },
      /* Size of the button. */
      size: {
        type: String,
        default: "small",
        validator: _size => ["small", "medium", "big"].includes(_size)
      },
      /* Active state of the button. */
      active: {
        type: Boolean,
        default: false
      },
      /*
       * The type of button corners.
       *
       * - square - No rounding at corners
       * - round - 6px rounding
       * - pill - 100px rounding
       * - circle - 50% rounding
       */
      corners: {
        type: String,
        default: "pill",
        validator: _corners => ["square", "round", "pill", "circle"].includes(_corners)
      },
      /*
       * Button modificators:
       * - only-text - Disables the backround, color will be applied text;
       * - padding-small - applies the custom padding for button (small from that list);
       * - padding-medium - applies the custom padding for button (medium from that list);
       * - padding-big - applies the custom padding for button (big from that list).
       */
      modificator: {
        type: [String, Array],
        default: "",
        validator: _mod => {
          if (!_mod) {
            return true;
          }

          if (!Array.isArray(_mod)) {
            _mod = [_mod];
          }

          const availableModificators = [
            "",
            "only-text",
            "padding-small",
            "padding-medium",
            "padding-big"
          ];

          return _mod.some(__mod => availableModificators.includes(__mod));
        }
      }
    }
  };
</script>
