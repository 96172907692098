import lodash from "lodash";
import axios from "axios";
import matchPlacement from "../../lib/match-placement.cjs";
import { getHash, setHash, updateHash } from "../lib/utils/url-query.js";

const hashQuery = getHash();
const placementQueryProperties = [
  "placement", "branchId", "departmentId", "locationId",
  "subLocationId", "branch", "department", "location",
  "subLocation", "dk5", "author", "dewey", "shelfmark"
];
const placementQuery = lodash.pick(hashQuery, placementQueryProperties);
setHash(lodash.omit(hashQuery, placementQueryProperties));

export function traverseLegend(list, iteratee) {
  if (!list || !Array.isArray(list))
    return;

  list.forEach(listElement => {
    iteratee(listElement);

    traverseLegend(listElement.sub, iteratee);
  });
}

function _resetViewStateRecursive(list) {
  traverseLegend(list, (listElement) => {
    listElement._isActive = false;
  });
}

function _recursiveFind(list, predicate) {
  if (!list || !Array.isArray(list))
    return [];

  let children = [];
  const found = list.find(listElement => {
    if (predicate(listElement))
      return true;

    children = _recursiveFind(listElement.sub, predicate);
    return children.length !== 0;
  });

  return [found].filter(Boolean).concat(children);
}

function parseMarkId(markId) {
  return (markId || "").split(",").map(markerId => (markerId || "").trim()).filter(Boolean);
}

function isActiveLegendElement(activeIds, _element) {
  const hasMarkers = (_element.markers || []).length !== 0;
  const hasSubElements = (_element.sub || []).length !== 0;

  return activeIds.includes(_element.id) && (hasMarkers || hasSubElements);
}

export default {
  state: () => ({
    loading: true,
    locales: null,
    keyboardMap: null,
    localesNames: null,
    view: null,
    error: null,
    selectedMarkers: parseMarkId(hashQuery.markId),
    layout: null
  }),
  actions: {
    selectMap({ commit }, mapId) {
      commit("SELECT_MAP", mapId);
    },
    setLegendElementsActiveStatus({ commit, state }, { elements, active }) {
      const elementsIds = elements.map(element => element.id);
      let view = lodash.cloneDeep(state.view);
      let modified = false;

      view.serviceButtons.forEach(serviceButton => {
        serviceButton._isActive = elementsIds.includes(serviceButton.id);
        modified = modified || elementsIds.includes(serviceButton.id);
      });

      view.maps.forEach(map => {
        if (elementsIds.includes(map.id)) {
          map._isActive = active;
          modified = true;
          if (active === false) {
            _resetViewStateRecursive(map.legend);
          }

          return;
        }

        const categoryPath = _recursiveFind(map.legend, isActiveLegendElement.bind(null, elementsIds));
        const servicePath = _recursiveFind(map.icons, isActiveLegendElement.bind(null, elementsIds));
        const hereMarkerPath = _recursiveFind(map.heres, isActiveLegendElement.bind(null, elementsIds));

        if (categoryPath.length !== 0 || hereMarkerPath.length !== 0) {
          map._isActive = true;
        }

        if (categoryPath.length !== 0 || servicePath.length !== 0 || hereMarkerPath.length !== 0) {
          modified = true;

          [categoryPath, servicePath, hereMarkerPath].forEach((path) => {
            if (active === true) {
              path.forEach(element => {
                element._isActive = true;
              });
            } else if (path.length !== 0) {
              path[path.length - 1]._isActive = false;
              _resetViewStateRecursive(path[path.length - 1].sub);
            }
          });
        }
      });

      if (modified)
        commit("SET_STATE", { view: view });
    },
    selectLegendElements({ dispatch, commit }, elements) {
      commit("RESET_SIMPLE_MARKERS_STATE");
      if (!Array.isArray(elements)) {
        elements = [elements].filter(Boolean);
      }

      dispatch("setLegendElementsActiveStatus", { elements, active: true });
      commit("SELECT_MAP_MARKERS", elements.map(element => element.id));
    },
    toggleLegendElement({ state, dispatch, commit }, element) {
      commit("RESET_SIMPLE_MARKERS_STATE");
      dispatch("setLegendElementsActiveStatus", { elements: [element], active: !element._isActive });
      if (!element._isActive) {
        return commit("SELECT_MAP_MARKERS", [element.id]);
      }

      if (element.legend)
        return commit("SELECT_MAP_MARKERS", []);

      state.view.maps.some(map => {
        let categoryPath = _recursiveFind(map.legend, (_element) => {
          return _element.id === element.id && (element.markers.length !== 0 || element.sub.length !== 0);
        });

        if (categoryPath.length !== 0) {
          categoryPath = [map].concat(categoryPath);
          dispatch("selectLegendElements", categoryPath[categoryPath.length - 2]);
        }

        return categoryPath.length !== 0;
      });
    },
    resetViewState({ commit, dispatch, state }) {
      commit("RESET_VIEW_STATE");
      if (hashQuery.markId == null && Object.keys(placementQuery).length !== 0) {
        const matchedPlacement = matchPlacement(state.view.maps, placementQuery);
        if (matchedPlacement.markId) {
          commit("SET_STATE", { selectedMarkers: parseMarkId(matchedPlacement.markId) });
        }
      } else if (hashQuery.markId) {
        commit("SET_STATE", { selectedMarkers: parseMarkId(hashQuery.markId) });
      } else {
        commit("SET_STATE", { selectedMarkers: [lodash.get(state, "view.maps[0].id")] });
      }

      const selectedLegendElements = [];
      /* Hack: Required for disable the highlight of the legend service buttons when the whole service buttons group is selected! */
      if (state.selectedMarkers.includes("service-buttons"))
        selectedLegendElements.push({ id: "service-buttons", _isActive: true });

      const isSelected = (legendElement) => {
        if (state.selectedMarkers.includes(legendElement.id))
          selectedLegendElements.push(legendElement);
      };

      traverseLegend(state.view.serviceButtons, isSelected);
      state.view.maps.forEach(map => {
        traverseLegend(map.icons, isSelected);
        traverseLegend(map.legend, isSelected);
        traverseLegend(map.heres, isSelected);
      });

      dispatch("selectLegendElements", selectedLegendElements);
    },
    async fetchView({ commit, dispatch }, { viewId }) {
      commit("SET_STATE", { loading: true, error: null });
      try {
        const localesResponse = await axios.get("/preferences/locales");
        const viewResponse = await axios.get(`/view/${ viewId }`);

        let serviceButtonsMap = {};
        (viewResponse.data.serviceButtons || []).forEach(serviceButton => {
          serviceButtonsMap[serviceButton.id] = serviceButton;
        });

        viewResponse.data.maps.forEach((map, index) => {
          map._index = index;
          map.icons.forEach(_serviceButton => {
            Object.assign(_serviceButton, lodash.omit(serviceButtonsMap[_serviceButton.serviceButtonId] || {}, "markers"));
          });
        });

        commit("SET_STATE", {
          locales: localesResponse.data.locales,
          keyboardMap: localesResponse.data.keyboardMap,
          localesNames: localesResponse.data.localesNames || {},
          view: viewResponse.data
        });
        dispatch("resetViewState");
      } catch (error) {
        commit("SET_STATE", { error: "View not found" });
        console.error(`Can't fetch view with id ${ viewId }.`, error);
      }

      commit("SET_STATE", { loading: false });
    }
  },
  mutations: {
    SET_STATE(state, newState) {
      Object.assign(state, newState);
    },
    SELECT_MAP(state, selectedMapId) {
      state.view.maps = state.view.maps.map(map => {
        return {
          ...map,
          _selected: map.id === selectedMapId
        };
      });
    },
    SELECT_MAP_MARKERS(state, selectedMarkers) {
      const previousActiveMapIndex = state.view.maps.findIndex(map => map._selected);

      const updatedMaps = state.view.maps.map(map => {
        const categoryPath = _recursiveFind(map.legend, (_element) => selectedMarkers.includes(_element.id));
        const servicePath = _recursiveFind(map.icons, isActiveLegendElement.bind(null, selectedMarkers));
        const hereMarkerPath = _recursiveFind(map.heres, isActiveLegendElement.bind(null, selectedMarkers));
        const directlySelected = selectedMarkers.includes(map.id);

        return {
          ...map,
          /* Mark all possible maps to selection */
          _selected: categoryPath.length !== 0
            || servicePath.length !== 0
            || hereMarkerPath.length !== 0
            || directlySelected
        };
      });

      let activeMapIndex = -1;
      if (previousActiveMapIndex === -1) {
        activeMapIndex = updatedMaps.findIndex(map => map._selected);

        if (activeMapIndex === -1)
          activeMapIndex = 0;
      } else {
        let checkingOrder = [previousActiveMapIndex];
        /* Get the checking order: previous active map; first map below; first map above; second map below; second map above, and etc. */
        for (let index = 1; index <= updatedMaps.length; index += 1) {
          const below = previousActiveMapIndex - index;
          if (below >= 0)
            checkingOrder.push(below);

          const above = previousActiveMapIndex + index;
          if (above < updatedMaps.length)
            checkingOrder.push(above);
        }

        activeMapIndex = checkingOrder.find(mapIndex => updatedMaps[mapIndex]._selected);
      }

      /* Select closest map to previously active map. */
      state.view.maps = updatedMaps.map((map, index) => {
        map._selected = index === activeMapIndex;

        return map;
      });
      state.selectedMarkers = selectedMarkers;
      updateHash({ markId: state.selectedMarkers.join(",") });
    },
    RESET_VIEW_STATE(state) {
      state.view.maps.forEach(map => {
        map._isActive = false;
        _resetViewStateRecursive(map.icons);
        _resetViewStateRecursive(map.legend);
        _resetViewStateRecursive(map.heres);
      });
    },
    RESET_SIMPLE_MARKERS_STATE(state) {
      state.view.maps.forEach(map => {
        _resetViewStateRecursive(map.icons);
        _resetViewStateRecursive(map.heres);
        traverseLegend(map.legend, (legendElement) => {
          const noLegend = !legendElement.legend || legendElement.legend.length === 0;
          const noChildren = !legendElement.sub || legendElement.sub.length === 0;
          if (noLegend && noChildren) {
            legendElement._isActive = false;
          }
        });
      });
    }
  }
};
