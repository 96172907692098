var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'wayfinder-map',
    {
      'wayfinder-map_dragging-in-progress': !_vm.ready || _vm.drag,
      'wayfinder-map_jump-animation': _vm.jumpAnimation,
      'wayfinder-map_here-marker-jump-animation': _vm.hereMarkerJumpAnimation
    }
  ],on:{"mousedown":_vm._dragStart,"touchstart":_vm._dragStart}},[_c('div',{staticClass:"wayfinder-map--map-image-wrapper",style:(_vm.mapStyles)},[_c('img',{ref:"scaledImage",staticClass:"wayfinder-map--map-image",attrs:{"draggable":"false","src":_vm.mapImage,"alt":_vm.$withLocale(this.selectedMap.label) + ' ' + _vm.$l10n('map')}})]),_c('div',{staticClass:"wayfinder-map--map-markers-wrapper",style:(_vm.mapStyles)},[_c('div',{staticClass:"wayfinder-map--map-markers"},[_vm._l((_vm.selectedLegendElements),function(legendElement){return [_vm._l((legendElement.markers),function(placement,placementIndex){return [(legendElement.type === 'point')?_c('div',{key:((legendElement.id) + "-" + placementIndex)},[_c('i',{staticClass:"wayfinder-map--map-marker wayfinder-map--location-dot fa-light fa-location-dot",style:({
                color: legendElement.color,
                top: placement.top,
                left: placement.left,
                fontSize: (_vm.markersFontSize + "px")
              })})]):(legendElement.type === 'area')?_c('svg',{key:((legendElement.id) + "-" + placementIndex),staticClass:"wayfinder-map--map-marker wayfinder-map--map-area",style:({
              color: legendElement.color,
              top: placement.top,
              left: placement.left,
              width: placement.width,
              height: placement.height,
              transform: ("scale(" + (_vm.$easyscreenCanvasOrientation.scale) + ")")
            })},[((placement.points || []).length !== 0)?_c('polygon',{style:({ fill: legendElement.color }),attrs:{"points":placement.points.map(function (point) { return ((point.x) + " " + (point.y)); }).join(', ')}}):_vm._e()]):(legendElement.type === 'here-marker')?_c('i',{key:((legendElement.id) + "-" + placementIndex),staticClass:"wayfinder-map--map-marker wayfinder-map--here-icon fa-light fa-crosshairs",style:({
              top: placement.top,
              left: placement.left,
              fontSize: (_vm.markersFontSize + "px")
            })}):_vm._e(),(legendElement.serviceButtonId)?_c('div',{key:((legendElement.id) + "-" + placementIndex)},[(_vm.theme === 'contrast-dark' || _vm.theme === 'contrast-light')?_c('div',{staticClass:"wayfinder-map--map-marker wayfinder-map--service-icon-light",style:({
                top: placement.top,
                left: placement.left,
                width: placement.width,
                height: placement.height,
                transform: ("scale(" + (_vm.$easyscreenCanvasOrientation.scale) + ")"),
                '--base-size': _vm.markersFontSize + 'px'
              })},[_c('p',{staticClass:"wayfinder-map--map-marker-title"},[_vm._v(_vm._s(_vm.$withLocale( legendElement.label)))]),_c('i',{class:['wayfinder-map--map-marker-icon', legendElement.class]}),_c('span',{staticClass:"wayfinder-map--map-marker-indicator"})]):_c('i',{class:[
                'wayfinder-map--map-marker',
                'wayfinder-map--service-icon',
                legendElement.class
              ],style:({
                top: placement.top,
                left: placement.left,
                fontSize: (_vm.serviceButtonsFontSize + "px")
              })})]):_vm._e()]})]})],2)]),_c('img',{directives:[{name:"onready",rawName:"v-onready",value:(_vm._updateDefaultScaleAndOffset),expression:"_updateDefaultScaleAndOffset"}],ref:"originalImage",staticClass:"wayfinder-map--map-original-image",attrs:{"src":_vm.mapImage,"alt":_vm.$withLocale(this.selectedMap.label) + ' ' + _vm.$l10n('map')}})])}
var staticRenderFns = []

export { render, staticRenderFns }