<template>
  <div :class="['wayfinder-legend', orientation]">
    <div class="wayfinder-legend--group">
      <category-legend
        v-for="map in filteredMaps"
        :key="map.id"
        :label="map.label"
        :legend="map.legend"
        :isActive="map._isActive"
        :element="map"
        @open-description="(event) => $emit('open-description', event)"
      />
    </div>
    <div class="wayfinder-legend--group">
      <service-legend :legend="serviceButtons" />
    </div>
  </div>
</template>

<style src="./legend.less" lang="less"></style>
<script>
  import { mapState } from "vuex";
  import CategoryLegend from "./category-legend.vue";
  import ServiceLegend from "./service-legend.vue";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "wayfinder-legend",
    mixins: [orientationMixin],
    computed: {
      ...mapState({
        view: state => state.view,
        legendFilter: state => state.legendFilter,
        serviceButtons(state) {
          if (!state.view.view)
            return [];

          if (state.legendFilter !== "") {
            return this.getServiceLegend(state);
          }

          return state.view.view.serviceButtons;
        }
      }),
      filteredMaps() {
        return this.$store.getters.filteredMaps;
      },
      serviceLegend() {
        let found = {};

        this.$store.getters.filteredMaps.forEach(map => {
          map.icons.forEach(serviceButton => {
            serviceButton.class = serviceButton.class.replace(/ {1,}/, " ");

            let key = [serviceButton.class, this.$withLocale(serviceButton.label)].join("--");
            if (!found[key])
              found[key] = { class: serviceButton.class, label: serviceButton.label, elements: [] };

            found[key].elements.push(serviceButton);
          });
        });

        return Object.keys(found).map(key => {
          found[key].id = found[key].elements.map(element => element.id).join(",");

          return found[key];
        });
      }
    },
    methods: {
      getServiceLegend(state) {
        let foundServiceButtons = [];

        this.filteredMaps.forEach(map => {
          if (map.icons.length !== 0) {
            foundServiceButtons = foundServiceButtons.concat(map.icons);
          }
        });

        return state.view.view.serviceButtons.filter(button =>
          foundServiceButtons.some(foundButton => foundButton.id === button.id)
        );
      }
    },
    components: {
      CategoryLegend,
      ServiceLegend
    }
  };
</script>
