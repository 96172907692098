var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'wayfinder',
    {
      'wayfinder_desktop': _vm.isDesktop && !_vm.isMobile,
      'wayfinder_mobile': _vm.isMobile,
      'wayfinder_wihtout-transition': !_vm.useTransition
    }
  ]},[(_vm.viewState.loading === true)?_c('loader',{class:[("wayfinder-loader_" + (this.$globalTheme))]}):(_vm.viewState.error)?_c('div',{staticClass:"wayfinder--error-message"},[_vm._v(" "+_vm._s(_vm.$l10n(_vm.viewState.error))+" ")]):[(_vm.currentTheme === 'contrast-dark' || _vm.currentTheme ==='contrast-light')?[_c('layout-content-contrast',{key:'contrast' + _vm.loadedDictionary,attrs:{"current-layout":_vm.currentLayout}})]:[_c('wayfinder-content',{key:'content-' + _vm.loadedDictionary}),_c('wayfinder-sidebar',{key:'sidebar-' + _vm.loadedDictionary})]],_c('focus-frame')],2)}
var staticRenderFns = []

export { render, staticRenderFns }