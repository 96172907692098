import lodash from "lodash";
import viewStore, { traverseLegend } from "./view.js";
import { getSearch, getHash, updateHash } from "../lib/utils/url-query.js";
import asyncTimeout from "../../dashboard/lib/async-timeout.js";

const hashQuery = getHash();
const searchQuery = getSearch();
const defaultUserLocale = hashQuery.lang || searchQuery.lang;
const hereMarkerId = hashQuery.here;

function recursuveFilterByLabel(list, options) {
  if (!list)
    return list;

  return list.map(listElement => {
    if (listElement.visible === false)
      return null;

    if (!options.filter)
      return listElement;

    listElement = { ...listElement };
    listElement.sub = recursuveFilterByLabel(listElement.sub, {
      ...options,
      parents: (options.parents || []).concat(listElement)
    });

    const matchedIndex = ( listElement.label && listElement.label[options.locale] || "").toLowerCase().indexOf(options.filter.toLowerCase());
    if (options.onMatch && matchedIndex !== -1) {
      options.onMatch(listElement, options.parents || []);
    }

    return matchedIndex !== -1 || (listElement.sub && listElement.sub.length !== 0) ? Object.assign(listElement, {
      _match: matchedIndex !== -1 ? { from: matchedIndex, to: matchedIndex + options.filter.length } : undefined
    }) : null;
  }).filter(Boolean);
}

const avalialableScales = ["", "wayfinder-scale_2"];

export default {
  state: {
    isMobile: null,
    isDesktop: null,
    sidebarOpened: true,
    keyboardPosition: "at-bottom",
    keyboardOpened: false,
    legendFilter: "",
    selectedLocale: null,
    useTransition: true,
    loadedDictionary: "",
    theme: "",
    layout: "",
    scaleIndex: 0
  },
  actions: {
    setScreenModificator({ commit }, options) {
      commit("SET_STATE", lodash.pick(options, ["isMobile", "isDesktop"]));
    },
    disableTransition({ commit }) {
      commit("SET_STATE", { useTransition: false });
    },
    async enableTransition({ commit }) {
      await asyncTimeout(100);
      commit("SET_STATE", { useTransition: true });
    },
    showSidebar({ commit }) {
      commit("SET_STATE", { sidebarOpened: true });
    },
    hideSidebar({ commit, state, dispatch }) {
      if (state.sidebarOpened === true) {
        dispatch("hideKeyboard");
      }

      commit("SET_STATE", { sidebarOpened: false });
    },
    toggleSidebar({ commit, state, dispatch }) {
      if (state.sidebarOpened === true) {
        dispatch("hideKeyboard");
      }

      commit("SET_STATE", { sidebarOpened: !state.sidebarOpened });
    },
    resetKeyboardPosition({ commit }) {
      commit("SET_STATE", { keyboardPosition: "on-top" });
    },
    toggleKeyboardPosition({ commit, state }) {
      if (state.keyboardPosition === "on-top") {
        commit("SET_STATE", { keyboardPosition: "at-bottom" });
      } else {
        commit("SET_STATE", { keyboardPosition: "on-top" });
      }
    },
    showKeyboard({ commit, state }) {
      if (state.isDesktop || state.isMobile)
        return;

      commit("SET_STATE", { keyboardOpened: true });
    },
    hideKeyboard({ commit }) {
      commit("SET_STATE", { keyboardOpened: false });
    },
    setLegendFilter({ commit, getters }, value) {
      commit("SET_STATE", { legendFilter: value });
      getters.filteredView.maps.forEach(map => {
        map._hits.forEach(hit => {
          if (hit.sub && hit.sub.length !== 0) {
            hit._isActive = true;
          }

          hit._hitParents.forEach(hitParent => {
            hitParent._isActive = true;
          });
        });
      });
    },
    selectLocale({ commit }, locale) {
      updateHash({ lang: locale });
      commit("SET_STATE", { selectedLocale: locale });
    },
    setLoadedDictionary({ commit }, locale) {
      commit("SET_STATE", { loadedDictionary: locale });
    },
    toggleLegendElement({ dispatch }, args) {
      dispatch("view/toggleLegendElement", args);
    },
    selectLegendElements({ state, dispatch }, options) {
      dispatch("view/selectLegendElements", options.elements);

      if (state.isMobile && options.autoClose !== false)
        dispatch("hideSidebar");
    },
    setTheme({ commit }, theme){
      commit("SET_STATE", { theme: theme });
    },
    changeScale({ commit, state }){
      let scaleIndex = state.scaleIndex + 1;

      if (avalialableScales[scaleIndex] == null) {
        scaleIndex = 0;
      }

      commit("SET_STATE", { scaleIndex: scaleIndex });
      document.body.className = avalialableScales[scaleIndex];
    },

    resetScale({ commit }) {
      commit("SET_STATE", { scaleIndex: 0 });
      document.body.className = avalialableScales[0];
    }
  },
  getters: {
    selectedMap(state) {
      const selectedMap = lodash.get(state, "view.view.maps", []).find(map => {
        return map._selected;
      });

      return selectedMap ? selectedMap : lodash.get(state, "view.view.maps[0]");
    },
    hereMarkerId(state) {
      if (!("here" in hashQuery))
        return false;

      let markerId;
      (lodash.get(state, "view.view.maps") || []).some(map => {
        const marker = map.heres.find(hereMarker => hereMarker.name === hashQuery.here);
        if (marker) {
          markerId = marker.id;
        }

        return marker;
      });

      return markerId;
    },
    selectedLegendElements(state, getters) {
      let markers = [];

      if (state.view.selectedMarkers.length !== 0) {
        const _match = (listElement) => {
          if (state.view.selectedMarkers.includes(listElement.id)) {
            markers.push(listElement);
          }
        };

        traverseLegend(state.view.view.serviceButtons, _match);
        traverseLegend(getters.selectedMap.icons, _match);
        traverseLegend(getters.selectedMap.legend, _match);
      }

      if (hereMarkerId != null) {
        const hereMarker = getters.selectedMap.heres.find(_hereMarker => {
          return _hereMarker.name == hereMarkerId;
        });

        if (hereMarker)
          markers.push({ ...hereMarker, type: "here-marker" });
      }

      /* Hack: Required for disable the highlight of the legend service buttons when the whole service buttons group is selected! */
      if (state.view.selectedMarkers.includes("service-buttons"))
        markers.push({ id: "service-buttons", _isActive: true });

      return markers;
    },
    filteredView(state, getters) {
      const view = lodash.cloneDeep(state.view.view);
      const filterOptions = {
        filter: state.legendFilter,
        locale: getters.selectedLocale
      };

      view.maps.forEach(map => {
        map._hits = [];
        map.icons = recursuveFilterByLabel(map.icons, filterOptions);
        map.legend = recursuveFilterByLabel(map.legend, {
          ...filterOptions,
          onMatch(element, parents) {
            element._hitParents = [map].concat(parents);
            map._hits.push(element);
          }
        });
        if ((map.legend.length !== 0 || map.icons.length !== 0) && state.legendFilter !== "") {
          map._isActive = true;
        }
      });

      return view;
    },
    filteredMaps(_, getters) {
      return getters.filteredView.maps;
    },
    selectedLocale(state) {
      let defaultLocale = lodash.get(state, "view.locales[0]");

      if (defaultUserLocale) {
        if ((lodash.get(state, "view.locales") || []).includes(defaultUserLocale)) {
          defaultLocale = defaultUserLocale;
        } else {
          console.warn("The locale from url search query not found. Will be used first defined locale.");
        }
      }

      return state.selectedLocale || defaultLocale;
    }
  },
  mutations: {
    SET_STATE(state, newState) {
      Object.assign(state, newState);
    }
  },
  modules: {
    view: { ...viewStore, namespaced: true }
  }
};
