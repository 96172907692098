<template>
  <div class="wayfinder-map-list">
    <div
      class="wayfinder-map-list--maps"
    >
      <div :style="mapListStyles" class="wayfinder-map-list--maps-wrapper">
        <wayfinder-map
          v-for="map in reversedMaps"
          :key="map.id"
          ref="maps"
          :map="map"
          class="wayfinder-map-list--map"
          @position-changed="_updateMapDefaultPosition(map._index, $event)"
        />
      </div>
    </div>
    <wayfinder-button-group
      v-if="$accessibility.zoomDisabled !== true"
      class="wayfinder-map-list--map-controls"
      gap-side="start" vertical
    >
      <wayfinder-button
        :aria-label-value="$l10n('zoom restore')"
        :class="[
          'wayfinder-map-list--map-control',
          { 'wayfinder-map-list--map-control_hidden': selectedMapInDefaultPostion }
        ]"
        :tabindex-value="selectedMapInDefaultPostion?-1:0"
        :title="$l10n('zoom restore')"
        color="black"
        corners="circle"
        @click.native="_resetMapPositionAndScale"
      >
        <i class="fa-light fa-arrows-rotate"></i>
      </wayfinder-button>
      <wayfinder-button
        :aria-label-value="$l10n('zoom down')"
        :title="$l10n('zoom down')"
        class="wayfinder-map-list--map-control"
        color="black"
        corners="circle"
        @click.native="_zoomIn"
      >
        <i class="fa-light fa-plus"></i>
      </wayfinder-button>
      <wayfinder-button
        :aria-label-value="$l10n('zoom up')"
        :title="$l10n('zoom up')"
        class="wayfinder-map-list--map-control"
        color="black"
        corners="circle"
        @click.native="_zoomOut"
      >
        <i class="fa-light fa-minus"></i>
      </wayfinder-button>
    </wayfinder-button-group>
    <wayfinder-button-group class="wayfinder-map-list--navigation">
      <wayfinder-button
        v-for="map in maps"
        :key="map.id"
        :active="map.id === selectedMap.id"
        :aria-label-value="$withLocale(map.label)"
        :title="$withLocale(map.label)"
        color="black"
        size="medium"
        @click.native="_selectMap(map.id)"
      >
        {{ $withLocale(map.label) }}
        <span v-if="map._isActive" class="wayfinder-map-list--active-map-marker">
          <i class="fa-solid fa-location-dot"></i>
        </span>

      </wayfinder-button>
    </wayfinder-button-group>
  </div>
</template>

<style lang="less" src="./map-list.less"></style>
<script>
  import lodash from "lodash";
  import { mapActions, mapState } from "vuex";
  import fixFloat from "../../lib/utils/fix-float.js";
  import orientationMixin from "../mixins/orientation.js";

  import WayfinderButtonGroup from "../button/button-group.vue";
  import WayfinderButton from "../button/button.vue";
  import WayfinderMap from "./map.vue";

  export default {
    name: "wayfinder-map-list",
    mixins: [orientationMixin],
    watch: {
      selectedMap() {
        this._resetMapsState();
      }
    },
    computed: {
      ...mapState({
        view: state => state.view.view
      }),
      maps() {
        return this.$store.getters.filteredMaps;
      },
      reversedMaps() {
        return this.maps.slice().reverse();
      },
      reversedSelectedMapIndex() {
        return (this.maps.length - 1) - this.selectedMap._index;
      },
      selectedMap() {
        return this.$store.getters.selectedMap;
      },
      mapListStyles() {
        return {
          top: `-${ this.reversedSelectedMapIndex * 100 }%`
        };
      },
      selectedMapInDefaultPostion() {
        return this.mapsDefaultPositionStatus[this.selectedMap._index] != false;
      },
      selectedMapInstance() {
        return this.$refs.maps[this.reversedSelectedMapIndex];
      },
      scaleStep() {
        if (!this.selectedMapInstance) {
          return 0;
        }

        return this.selectedMapInstance.defaultScale * 0.5;
      },
      minScale() {
        if (!this.selectedMapInstance) {
          return 1;
        }

        return this.selectedMapInstance.defaultScale * 0.5;
      },
      maxScale() {
        if (!this.selectedMapInstance) {
          return 1;
        }

        return this.selectedMapInstance.defaultScale * 2;
      }
    },
    data() {
      return {
        mapsDefaultPositionStatus: (this.maps || []).map(() => true)
      };
    },
    methods: {
      ...mapActions({
        __selectMap: "view/selectMap"
      }),
      _updateMapDefaultPosition(mapIndex, instance) {
        this.mapsDefaultPositionStatus = this.mapsDefaultPositionStatus.slice();
        this.mapsDefaultPositionStatus[mapIndex] = instance.isDefaultPosition && fixFloat(instance.scale) === fixFloat(instance.defaultScale);
      },
      _resetMapPositionAndScale() {
        if (!this.selectedMapInstance)
          return;

        this.selectedMapInstance._resetMapPositionAndScale();
      },
      _selectMap(mapId) {
        if (this.selectedMap.id == mapId)
          return;

        this._resetMapsState();
        this.__selectMap(mapId);
      },
      _zoomIn() {
        if (!this.selectedMapInstance)
          return;

        this.selectedMapInstance.scale = lodash.clamp(this.selectedMapInstance.scale + this.scaleStep, this.minScale, this.maxScale);
        this._updateMapDefaultPosition(this.selectedMap._index, this.selectedMapInstance);
      },
      _zoomOut() {
        if (!this.selectedMapInstance)
          return;

        this.selectedMapInstance.scale = lodash.clamp(this.selectedMapInstance.scale - this.scaleStep, this.minScale, this.maxScale);
        this._updateMapDefaultPosition(this.selectedMap._index, this.selectedMapInstance);
      },
      _resetMapsState() {
        this.mapsDefaultPositionStatus = this.$refs.maps.map(mapInstance => {
          mapInstance._resetMapPositionAndScale();

          return true;
        });
      }
    },
    mounted() {
      this.$on("orientation-changed", this._resetMapsState);
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._resetMapsState);
    },
    components: {
      WayfinderButtonGroup,
      WayfinderButton,
      WayfinderMap
    }
  };
</script>
